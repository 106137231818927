@font-face {
  font-family: 'serverless';
  src: url('./fonts/serverless-regular.woff2') format('woff2'),
    url('./fonts/serverless-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.vaporwave-background-container {
  position: absolute;
  /* top: 0;
  bottom: 0; */
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.vaporwave-background {
  /* Make video to at least 100% wide and tall */
  min-width: 100%;
  min-height: 100%; 
  filter: brightness(0.4);
  
  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: auto;
  height: auto; 
  
  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

html,
body{
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  line-height: normal;
  background: var(--mainBg);
  font-family: 'serverless', 'HelveticaNeue-Regular', 'Helvetica Neue Regular', 'Helvetica Neue',
    Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  word-wrap: break-word;
  letter-spacing: 0.25px;
  background: rgb(12, 7, 11);
}

.landing-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin: 0;
}

.hero {
  align-items: center;
  justify-content: center;
  height: auto;
  min-height: 40vh;
  animation-duration: 2000ms;
  animation-name: fadeIn;
}

.hero img {
  max-height: 40vh;
  pointer-events: none;
}

.tagline, .pretitle{
  align-items: center;
  height: auto;
  color: #ffffff;
  font-size: 18px;
  text-align: center;
  opacity: 0;
  animation-fill-mode: forwards;
  animation-delay: 500ms;
  animation-duration: 2000ms;
  animation-name: fadeIn;
  margin: 0;
}

.main-title{
  font-family: 'Open Sans', sans-serif;
  align-items: center;
  color: #ffffff;
  font-size: 80px;
  text-align: center;
  opacity: 0;
  animation-fill-mode: forwards;
  animation-delay: 500ms;
  animation-duration: 2000ms;
  animation-name: fadeIn;
  line-height: 64pt;
  margin: 3rem 0;
}

.buttonContainer {
  height: auto;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
  opacity: 0;
  animation-fill-mode: forwards;
  animation-delay: 1000ms;
  animation-duration: 2000ms;
  animation-name: fadeIn;
}


/**
 * Animations
 */

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* body {
  font-family: "Muli";
} */

.signup-button {

  display: flex;
  margin-top: 40px;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-flow: column;

  opacity: 0;
  animation-fill-mode: forwards;
  animation-delay: 500ms;
  animation-duration: 2000ms;
  animation-name: fadeIn;
}
.signup-button a {
  width: 100%;
  max-width: 240px;
  height: 70px;
  padding: 8px;
  font-size: 0.8rem;
  font-weight: 900;
  color: linear-gradient(90deg, #0E0823 0%, #530A37 100%);
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  box-shadow: 0 0 0 1px inset rgba(236, 232, 225, 0.3);
  position: relative;
  margin: 10px 0;
}
.signup-button a.transparent:hover > p {
  color: #ece8e1;
}
.signup-button a.transparent:hover > p span.text {
  box-shadow: 0 0 0 1px #ece8e1;
}
.signup-button a.transparent > p {
  background: #0f1923;
  color: #ece8e1;
}
.signup-button a.transparent > p span.base {
  border: 1px solid #ece8e1;
}
.signup-button a:after, .signup-button a:before {
  content: "";
  width: 1px;
  position: absolute;
  height: 8px;
  background: #0f1923;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.signup-button a:before {
  right: 0;
  left: initial;
}
.signup-button a p {
  margin: 0;
  height: 54px;
  line-height: 54px;
  box-sizing: border-box;
  z-index: 1;
  left: 0;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.signup-button a p span.base {
  box-sizing: border-box;
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  left: 0;
  border: 1px solid #ff4655;
}
.signup-button a p span.base:before {
  content: "";
  width: 2px;
  height: 2px;
  left: -1px;
  top: -1px;
  background: #0f1923;
  position: absolute;
  -webkit-transition: 0.3s ease-out all;
  transition: 0.3s ease-out all;
}
.signup-button a p span.bg {
  left: -5%;
  position: absolute;
  background: linear-gradient(90deg, #0E0823 0%, #530A37 100%);
  width: 0;
  height: 100%;
  z-index: 3;
  -webkit-transition: 0.3s ease-out all;
  transition: 0.3s ease-out all;
  -webkit-transform: skewX(-10deg);
          transform: skewX(-10deg);
}
.signup-button a p span.text {
  z-index: 4;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.signup-button a p span.text:after {
  content: "";
  width: 4px;
  height: 4px;
  right: 0;
  bottom: 0;
  background: #0f1923;
  position: absolute;
  -webkit-transition: 0.3s ease-out all;
  transition: 0.3s ease-out all;
  z-index: 5;
}
.signup-button a:hover {
  color: #ece8e1;
}
.signup-button a:hover span.bg {
  width: 110%;
}
.signup-button a:hover span.text:after {
  background: #ece8e1;
}



:root {
  --primary: #22D2A0;
  --secondary: #192824;
  --background: #192824;
  --green: #1FC11B;
  --yellow: #FFD913;
  --orange: #FF9C55;
  --red: #FF5555;
} 

/* *{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
} */

/* body {
  background-image: radial-gradient(var(--secondary) 30%, var(--background) 30%);
  background-size: 2px 3px;
  font-family: "Archivo", sans-serif;
  color: var(--primary);
} */

.content {
  display: flex;
  flex-direction: row;  
}

.welcome-container {
  display: flex;
  width: 50%;
  border: 3px solid var(--primary);
}

.card-header {
  color: #fff;
  border-radius: 16px;
  background: #17141d;
  box-shadow: -1rem 0 3rem #000;
  transition: .2s;
  cursor: pointer;
}

.card {
  border: 0;
  border-radius: 0;
  margin-bottom: 1rem;
}

.card-body {
  background: #0C070B;
  color: #fff;
}

@import url('https://fonts.googleapis.com/css2?family=DM+Mono:wght@300;400;500&display=swap');

/* body {
    padding: 0;
    margin: 0;
    background-color: #17141d;
    color: white;
    font-family: 'DM Mono', monospace;
} */

.card-list {
    display: flex;
    padding: 3rem;
    overflow-x: auto;
}


.card-list::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
.card-list::-webkit-scrollbar-thumb {
    background: #201c29;
    border-radius: 10px;
    box-shadow: inset 2px 2px 2px hsla(0,0%,100%,.25), inset -2px -2px 2px rgba(0,0,0,.25);
}

.card-list::-webkit-scrollbar-track {
    background: linear-gradient(90deg,#201c29,#201c29 1px,#17141d 0,#17141d);
}


.event-card {
    display: flex;
    position: relative;
    flex-direction: column;
    height: 350px;
    width: 400px;
    min-width: 250px;
    padding: 1.5rem;
}

.event-card {
  /* ... */

  border-radius: 16px;
  background: #17141d;
  box-shadow: -1rem 0 3rem #000;

  transition: .2s;
}

.event-card:hover {
  transform: translateY(-1rem);
}

.event-card:hover~.card {
  transform: translateX(130px);
}


.event-card:not(:first-child) {
  margin-left: -10px;
}

.card-author {
  position: relative;
  display: grid;
  grid-template-columns: 75px 1fr;
  align-items: center;
  margin: 3rem 0 0;
  color: #7a7a8c;
}

.author-avatar img {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  filter: grayscale(100%);
  margin: 16px 10px;
}

.half-circle-primary {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 48px;
  fill: none;
  stroke: var(--primary);
  stroke-width: 8;
  stroke-linecap: round;
}

.half-circle-green {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 48px;
  fill: none;
  stroke: var(--green);
  stroke-width: 8;
  stroke-linecap: round;
}

.half-circle-yellow {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 48px;
  fill: none;
  stroke: var(--yellow);
  stroke-width: 8;
  stroke-linecap: round;
}

.half-circle-orange {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 48px;
  fill: none;
  stroke: var(--orange);
  stroke-width: 8;
  stroke-linecap: round;
}

.event-card-header {
  margin-bottom: auto;
}

.event-card-header-primary p, .event-card-header-green p, .event-card-header-yellow p, .event-card-header-orange p {
  font-size: 14px;
  margin: 0 0 1rem;
  color: #7a7a8c;
}

.event-card-header-primary h2 {
  font-size: 20px;
  margin: .25rem 0 auto;
  cursor: pointer;
  background: var(--primary);
  text-shadow: none;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.event-card-header-green h2 {
  font-size: 20px;
  margin: .25rem 0 auto;
  cursor: pointer;
  background: var(--green);
  text-shadow: none;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.event-card-header-yellow h2 {
  font-size: 20px;
  margin: .25rem 0 auto;
  cursor: pointer;
  background: var(--yellow);
  text-shadow: none;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.event-card-header-orange h2 {
  font-size: 20px;
  margin: .25rem 0 auto;
  cursor: pointer;
  background: var(--orange);
  text-shadow: none;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.tags {
  margin: 1rem 0 2rem;
  padding: .5rem 0 1rem;
  line-height: 2;
  margin-bottom: 0;
}

.tags a {
  font-style: normal;
  font-weight: 700;
  font-size: .5rem;
  color: #7a7a8c;
  text-transform: uppercase;
  font-size: .66rem;
  border: 3px solid #28242f;
  border-radius: 2rem;
  padding: .2rem .85rem .25rem;
  position: relative;
}

.tags a:hover {
  background: linear-gradient(90deg,#ff8a00,#e52e71);
  text-shadow: none;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-box-decoration-break: clone;
  background-clip: text;
  border-color: white;
}
ul {
  padding: 0;
}

.hover-primary:hover {
  color: var(--primary);
}

.hover-green:hover {
  color: var(--green);
}

.hover-yellow:hover {
  color: var(--yellow);
}

.hover-orange:hover {
  color: var(--orange);
}

.hover-red:hover {
  color: var(--red);
}